import React, { useState, useMemo, useEffect } from 'react';
import { graphql } from 'gatsby'
import moment from 'moment/moment';
import { StaticImage } from 'gatsby-plugin-image';
import { Jumbotron, Container, Row, Col, Button, ButtonGroup } from 'reactstrap';
import { useIntl, Link } from "gatsby-plugin-intl"
import Map, { Marker, Popup, AttributionControl, type ViewStateChangeEvent } from 'react-map-gl/maplibre';
import tinycolor from "tinycolor2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudShowersHeavy, faCloudRain, faPlane, faHotel } from '@fortawesome/free-solid-svg-icons'
import { faWikipediaW } from '@fortawesome/free-brands-svg-icons';

import SEO from '../components/seo';
import strings from '../utils/strings';
import colors from '../utils/colors';
import staticdata from '../utils/staticdata';

const RAINFALL_TRESHOLD = 150;
const HEAVY_RAINFALL_TRESHOLD = 300;
const INITIAL_ZOOM_LEVEL = 1;
const CUSTOM_ATTRIBUTION = '<a href="https://www.jaysquared.com/en/imprint/" target="_blank">Imprint</a> | <a href="https://www.jaysquared.com/en/terms/service/website/en/" target="_blank">Terms</a> | <a href="https://www.jaysquared.com/en/terms/privacy/website/en/" target="_blank">Privacy</a> | <a href="https://worldweather.wmo.int/" target="_blank">WMO World Weather Information Service</a>';
const TEMPERATURE_UNITS = {
    CELCIUS: 'celcius',
    FAHRENHEIT: 'fahrenheit'
}

const cToF = (celsius) => {
    return celsius * 9 / 5 + 32;
}

const MapPage = (params) => {

    let { month: currentMonthKey } = params.pageContext;
    if (!currentMonthKey) {
        currentMonthKey = moment().format('MMM').toLowerCase();
    }
    const [monthKey, setMonthKey] = useState(currentMonthKey);
    const [tempUnit, setTempUnit] = useState(TEMPERATURE_UNITS.CELCIUS);

    const currentMonth = staticdata.MONTHS[monthKey];
    const { data } = params;

    const [currentCityId, setCurrentCityId] = useState(null);
    const [currentZoomLevel, setCurrentZoomLevel] = useState(INITIAL_ZOOM_LEVEL);

    let popupInfo = null;
    if (currentCityId) {
        const currentCity = data.allLocations.edges.filter((edge) => edge.node.id === currentCityId)[0].node;

        const { latitude, longitude, name, city, googleFlightsName, wikipedia } = currentCity;

        popupInfo = {
            latitude,
            longitude,
            name,
            googleFlightsName,
            wikipedia,
            climate: city.climate.climateMonth[currentMonth.id]
        }
    }

    useEffect(() => {
        // Update the document title using the browser API        
        var newurl = window.location.protocol + "//" + window.location.host + '/en/' + monthKey;
        window.history.pushState({ path: newurl }, '', newurl);
    });

    const pins = useMemo(() => data.allLocations.edges.map(edge => {
        const { id, city, latitude, longitude, numHotels, closestLocationDistance, closestLocationNumHotels } = edge.node;
        if (
            (closestLocationDistance < 200 && currentZoomLevel < 3) ||
            (closestLocationDistance < 100 && currentZoomLevel < 4) ||
            (closestLocationDistance < 50 && currentZoomLevel < 5) ||
            (closestLocationDistance < 10 && currentZoomLevel < 6)
        ) {
            //console.log('closer than 100km', { currentZoomLevel, numHotels, closestLocationNumHotels });
            if (numHotels < closestLocationNumHotels) {
                return null;
            }
        }


        const climate = city.climate.climateMonth[currentMonth.id];
        if (Number(climate.maxTemp) === 0 && Number(climate.minTemp) === 0)
            return null;

        const averageTemp = Math.round(climate.maxTemp);// Math.round((Number(climate.maxTemp) + Number(climate.minTemp)) / 2);
        const displayTemperature = (tempUnit === TEMPERATURE_UNITS.FAHRENHEIT) ? Math.round(cToF(averageTemp)) : averageTemp;
        const backgroundColor = colors.getColorForTemperature(averageTemp);
        const textColor = colors.getTextColorForBackground(backgroundColor);
        const rainfall = Number(climate.rainfall);
        let rainIcon = null;
        if (rainfall > HEAVY_RAINFALL_TRESHOLD) {
            rainIcon = faCloudShowersHeavy;
        } else if (rainfall > RAINFALL_TRESHOLD) {
            rainIcon = faCloudRain;
        }
        return (<Marker
            key={String(id)}
            longitude={Number(longitude)}
            latitude={Number(latitude)}
            anchor="bottom"
            onClick={e => {
                // If we let the click event propagates to the map, it will immediately close the popup
                // with `closeOnClick: true`
                e.originalEvent.stopPropagation();
                setCurrentCityId(id);
            }}
        >
            <div className="city" style={{ backgroundColor, color: textColor }}>
                {displayTemperature}°
                {rainIcon && <div className="rainfall"><FontAwesomeIcon icon={rainIcon} /></div>}
            </div>

        </Marker>)
    }), [currentZoomLevel, monthKey, tempUnit]);


    const intl = useIntl();

    return (
        <div id="home">
            <SEO />
            <div id="header">
                <Row className="align-items-center">
                    <Col xs={12} lg="auto" className="pb-2 pb-lg-0">
                        <h1 id="logo" className="text-center">TravelWeatherMap</h1>
                        <h2 id="subtitle" className="text-center d-none d-lg-block">Seasonal weather at a glance</h2>
                    </Col>
                    <Col>
                        <Row id="months" className="justify-content-center">
                            {Object.keys(staticdata.MONTHS).map(key => {
                                const month = staticdata.MONTHS[key];
                                const active = monthKey === key;
                                const className = (active) ? 'month active' : 'month';
                                return (
                                    <Col key={key} className={className} xs="auto">
                                        <div onClick={() => setMonthKey(key)}>
                                            <span className="d-none d-xl-block">{month.title}</span>
                                            <span className="d-xl-none">{strings.capitalize(key)}</span>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                </Row>
            </div>
            <Map
                initialViewState={{
                    zoom: 1
                }}
                id="map"
                mapStyle="https://api.maptiler.com/maps/dataviz/style.json?key=87UNO43mHDOOAi8XJ4V8"
                customAttribution={CUSTOM_ATTRIBUTION}
                reuseMaps
                onZoomEnd={(event: ViewStateChangeEvent) => setCurrentZoomLevel(event.viewState.zoom)}
            >
                <div className="p-2">
                    <ButtonGroup>
                        <Button size='sm' className="shadow-none font-weight-bold" color={tempUnit === TEMPERATURE_UNITS.CELCIUS ? 'primary' : 'light'} onClick={() => setTempUnit(TEMPERATURE_UNITS.CELCIUS)}>
                            C
                        </Button>
                        <Button size='sm' className="shadow-none font-weight-bold" color={tempUnit === TEMPERATURE_UNITS.FAHRENHEIT ? 'primary' : 'light'} onClick={() => setTempUnit(TEMPERATURE_UNITS.FAHRENHEIT)}>
                            F
                        </Button>
                    </ButtonGroup>
                </div>
                {pins}
                {
                    popupInfo &&
                    <Popup
                        anchor="top"
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        onClose={() => setCurrentCityId(null)}
                        closeButton={null}
                        className="cityDetails"
                    >
                        <div className="title">
                            {popupInfo.name}
                        </div>
                        <div className="climate">
                            <div className="box" style={{ backgroundColor: colors.getColorForTemperature(Math.round(popupInfo.climate.minTemp)) }}>
                                <div className="value">{Math.round(popupInfo.climate.minTemp)}°C</div>
                                <div className="label">Min</div>
                            </div>
                            <div className="box" style={{ backgroundColor: colors.getColorForTemperature(Math.round(popupInfo.climate.maxTemp)) }}>
                                <div className="value">{Math.round(popupInfo.climate.maxTemp)}°C</div>
                                <div className="label">Max</div>
                            </div>
                            <div className="box rain">
                                <div className="value">{Math.round(popupInfo.climate.raindays)} d</div>
                                <div className="label">Rain</div>
                            </div>
                            <div className="box rain">
                                <div className="value">{Math.round(popupInfo.climate.rainfall)} mm</div>
                                <div className="label">Rain</div>
                            </div>
                        </div>
                        <hr />
                        <div className="links">
                            <a href={'https://www.google.com/travel/flights?q=Flights%20to%20' + popupInfo.googleFlightsName} target="_blank" className="link">
                                <div className="icon"><FontAwesomeIcon icon={faPlane} /></div>
                                Flights</a>
                            <a href={'http://www.google.com/maps/search/hotel/@' + popupInfo.latitude + ',' + popupInfo.longitude + ',13z'} target="_blank" className="link">
                                <div className="icon"><FontAwesomeIcon icon={faHotel} /></div>
                                Hotels</a>

                            <a href={popupInfo.wikipedia} className="link" target="_blank">
                                <div className="icon"><FontAwesomeIcon icon={faWikipediaW} /></div>
                                Wikipedia</a>

                        </div>
                    </Popup>
                }

            </Map >
        </div >
    );
}


export const query = graphql`
  query MapPageQuery {
    allLocations {
        edges {
          node {
            id
            name
            wikipedia
            latitude
            longitude
            googleFlightsName
            numHotels
            closestLocationDistance
            closestLocationNumHotels
            city {
              climate {
                climateMonth {
                  maxTemp
                  minTemp
                  month
                  raindays
                  rainfall
                }
              }
            }
          }
        }
      }
  }
`

export default MapPage;
