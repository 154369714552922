//@flow

const MONTHS = {
    jan: {
        title: "January",
        id: 0
    },
    feb: { title: "February", id: 1 },
    mar: { title: "March", id: 2 },
    apr: { title: "April", id: 3 },
    may: { title: "May", id: 4 },
    jun: { title: "June", id: 5 },
    jul: { title: "July", id: 6 },
    aug: { title: "August", id: 7 },
    sep: { title: "September", id: 8 },
    oct: { title: "October", id: 9 },
    nov: { title: "November", id: 10 },
    dec: { title: "Dezember", id: 11 }
}



export default {
    MONTHS
}