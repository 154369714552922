//@flow

import tinycolor from "tinycolor2";

const getColorForTemperature = (nowTemp: number): string => {
    // following hsl wheel counterclockwise from 0
    // to go clockwise, make maxHsl and minHsl negative 
    // nowTemp = 70;
    var maxHsl = 380; // maxHsl maps to max temp (here: 20deg past 360)
    var minHsl = 170; //  minhsl maps to min temp counter clockwise
    var rngHsl = maxHsl - minHsl; // = 210

    var maxTemp = 40;
    var minTemp = -20;
    var rngTemp = maxTemp - minTemp; // 125
    var degCnt = maxTemp - nowTemp; // 0
    var hslsDeg = rngHsl / rngTemp;  //210 / 125 = 1.68 Hsl-degs to Temp-degs
    var returnHue = (360 - ((degCnt * hslsDeg) - (maxHsl - 360)));
    return '#' + tinycolor(`hsl(${returnHue}, 80%, 60%)`).toHex();
}

const getTextColorForBackground = (color: string): string => {
    if (tinycolor(color).getBrightness() > 200) {
        return '#000000';
    }
    return '#ffffff';
}

export default {
    getColorForTemperature,
    getTextColorForBackground
}